// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"maxWidthForHamburger": "750px",
	"secondaryColor": "#3a5a40",
	"secondaryColorLighter": "#588157",
	"secondaryColorDarkest": "#344e41",
	"secondaryColorHighlight": "#a3b18a",
	"secondaryColorLightest": "#dad7cd",
	"primaryColor": "#2e3440",
	"primaryColorAlt": "#464e5e",
	"primaryBackground": "#eceff4",
	"primaryBackgroundLighter": "#f2f4f7",
	"primaryColorHighlight": "#e5e9f0",
	"warningColor": "#942911"
};
module.exports = exports;
