import React from "react";

type mushroomProps = {
    color: string;
    className?: string;
};
const Mushroom = ({color, className}: mushroomProps): JSX.Element => {
    return (
        <svg viewBox="0 0 1000 1000" className={className}>
            <g>
                <path
                    fill={color}
                    d="M573.258,453.6c-33.1,4.5-69.899,6.8-109.399,6.8c-65.701,0-117-6.3-123.701-7.201c-6.601-0.6-13.2-1.3-19.7-2
		c-26.4,139.5-60.8,476.3,226.5,466.4C876.958,906.4,528.058,667.699,573.258,453.6z"
                />
                <path
                    fill={color}
                    d="M458.958,0c-190.7,0-352.4,142.2-368.1,323.7c-1.7,20.1,9.3,39.4,28.1,48.8c3.5,1.8,71.1,35,207.6,49.399
		c5.5,0.601,11.2,1.101,16.9,1.7c0,0,52.6,7,120.401,7c34.399,0,116-7.8,119-8.1c141.1-14,211.899-47.9,215.6-49.7
		c19-9.3,30.2-28.7,28.5-49C811.258,142.2,649.658,0,458.958,0z M204.357,335.9c-22.6,0-41.4-18.8-41.4-41.4s18.8-41.4,41.4-41.4
		s41.4,18.8,41.4,41.4S226.958,335.9,204.357,335.9z M614.158,100c22.6,0,41.399,18.8,41.399,41.4c0,22.6-18.8,41.4-41.399,41.4
		c-22.601,0-41.4-18.8-41.4-41.4C572.758,118.8,591.658,100,614.158,100z M423.757,102c22.601,0,41.401,18.8,41.401,41.4
		c0,22.6-18.8,41.4-41.401,41.4c-22.6,0-41.399-18.8-41.399-41.4C382.358,120.8,401.257,102,423.757,102z M616.258,340
		c-22.6,0-41.399-18.8-41.399-41.4s18.8-41.4,41.399-41.4c22.601,0,41.4,18.8,41.4,41.4S638.758,340,616.258,340z"
                />
            </g>
        </svg>
    );
};

export default Mushroom;
