import React from "react";
import {LogoProps} from "./LogoTypes";

const PythonLogo = ({LogoPrimaryColor, LogoSecondaryColor, Link}: LogoProps): JSX.Element => {
    return (
        <a href={Link}>
            <svg height="100%" width="100%" viewBox="0 0 512 512">
                <path
                    d="m116 296c0-30.328125 24.671875-55 55-55h170c13.785156 0 25-11.214844 25-25v-141c0-41.355469-33.644531-75-75-75h-70c-41.355469 0-75 33.644531-75 75v41h110c8.285156 0 15 6.714844 15 15s-6.714844 15-15 15h-181c-41.355469 0-75 33.644531-75 75v70c0 41.355469 33.644531 75 75 75h41zm105-220c-8.285156 0-15-6.714844-15-15s6.714844-15 15-15 15 6.714844 15 15-6.714844 15-15 15zm0 0"
                    fill={LogoPrimaryColor}
                />
                <path
                    d="m437 146h-41v70c0 30.328125-24.671875 55-55 55h-170c-13.785156 0-25 11.214844-25 25v141c0 41.355469 33.644531 75 75 75h70c41.355469 0 75-33.644531 75-75v-41h-110c-8.285156 0-15-6.714844-15-15s6.714844-15 15-15h181c41.355469 0 75-33.644531 75-75v-70c0-41.355469-33.644531-75-75-75zm-146 290c8.285156 0 15 6.714844 15 15s-6.714844 15-15 15-15-6.714844-15-15 6.714844-15 15-15zm0 0"
                    fill={LogoSecondaryColor}
                />
            </svg>
        </a>
    );
};

export default PythonLogo;
